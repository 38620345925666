import React from "react";

function App() {
  return (
    <div id="cv-render">
      <div className="cv-wrapper customize-preview">
        <div>
          <div className="page-wrapper template sunny a4 lit-hin fs-13 lh-16 yel-gre">
            <div>
              <div className="outer-page grid-50-50">
                <div className="page first-page" data-part="1" data-count="1">
                  <div className="top-ornament"></div>
                  <div className="general-section  ">
                    <div className="avatar">
                      <div className="thumbnail">
                        <svg
                          viewBox="0 0 103 103"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="dotted round"
                        >
                          <circle cx="50%" cy="50%" r="50"></circle>
                        </svg>
                        <img src="sushant.jpeg" alt="avatar" />
                      </div>
                    </div>
                    <h2 className="name ">
                      <span> Sushant Bansal </span>
                    </h2>
                    <div className="general-info inline-info ">
                      <span className="value">Full Stack Developer</span>
                      <span className="separator">|</span>
                      <span className="value">Chandigarh, India</span>
                      <span className="separator">|</span>
                      <a className="value" href="tel:+91-7015336689">
                        +91-7015336689
                      </a>
                      <span className="separator">|</span>
                      <a
                        className="value"
                        href="mailto:bansalss001@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer nofollow ugc"
                      >
                        bansalss001@gmail.com
                      </a>
                    </div>
                  </div>
                  <div className="work-content">
                    <div className="column left-column-render">
                      <div
                        className="column-wrapper rich-text-section kickresume-section-wrapper"
                        id="profile"
                      >
                        <h6 className="headline type-three">
                          <div className="background-wrapper">
                            <span className="icon fixed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 12c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6zm0-10.5C9.519 1.5 7.5 3.519 7.5 6s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5zM21.75 24a.75.75 0 01-.75-.75c0-4.963-4.037-9-9-9s-9 4.037-9 9a.75.75 0 01-1.5 0c0-5.79 4.71-10.5 10.5-10.5s10.5 4.71 10.5 10.5a.75.75 0 01-.75.75z"></path>
                              </svg>
                            </span>
                            <span className="text">Profile</span>
                          </div>
                        </h6>
                        <div className="text-area rich-text-area">
                          <div part="0">
                            <div className="description " part="0">
                              <span>With </span>
                              <span>motive </span>
                              <span>to </span>
                              <span>bring </span>
                              <span>my </span>
                              <span>work, </span>
                              <span>my </span>
                              <span>identity. </span>
                              <span>I </span>
                              <span>work </span>
                              <span>on </span>
                              <span>my </span>
                              <span>strengths </span>
                              <span>and </span>
                              <span>bring </span>
                              <span>best </span>
                              <span>to </span>
                              <span>my </span>
                              <span>work.</span>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                      <div className="kickresume-section-wrapper experience-section  column-wrapper ">
                        <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                          &nbsp;
                        </div>
                        <h6 className="headline type-three">
                          <div className="background-wrapper">
                            <span className="icon fixed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M2.25 23.25A2.252 2.252 0 010 21V7.5a2.252 2.252 0 012.25-2.25H3V4.5a.75.75 0 011.5 0v.75h2.459l.987-2.962A2.247 2.247 0 0110.08.75h3.838c.97 0 1.827.618 2.135 1.538l.987 2.962h2.46V4.5a.75.75 0 011.5 0v.75h.75A2.252 2.252 0 0124 7.5V21a2.252 2.252 0 01-2.25 2.25H2.25zm0-16.5a.75.75 0 00-.75.75V21c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75V7.5a.75.75 0 00-.75-.75H2.25zm13.209-1.5l-.829-2.488a.749.749 0 00-.711-.512h-3.838a.748.748 0 00-.711.513L8.541 5.25h6.918z"></path>
                                <path d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25zm0 6a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"></path>
                              </svg>
                            </span>
                            <span className="text">Work experience</span>
                          </div>
                        </h6>
                        <div className="experience-item">
                          <div className="experience-info">
                            <div className="date  with-space ">
                              <span className="date-numbers">
                                09/2021 – present
                              </span>
                              <span className="date-address">
                                <span>Remote</span>
                              </span>
                            </div>
                            <div className="position">Software Engineer</div>
                            <div className="company-name">TheGuarantors</div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0"></div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                        <div className="experience-item">
                          <div className="experience-info">
                            <div className="date  with-space ">
                              <span className="date-numbers">
                                09/2020 – 12/2022
                              </span>
                              <span className="date-address">
                                <span>Remote</span>
                              </span>
                            </div>
                            <div className="position">Product Developer</div>
                            <div className="company-name">Wellness Coach</div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0">
                                <span>Working </span>
                                <span>with </span>
                                <span>responsibilities: </span>
                                <span>⦿ </span>
                                <span>Enhance </span>
                                <span>product </span>
                                <span>with </span>
                                <span>new </span>
                                <span>feature. </span>
                                <span>⦿ </span>
                                <span>Guide </span>
                                <span>and </span>
                                <span>help </span>
                                <span>pears </span>
                                <span>to </span>
                                <span>improve </span>
                                <span>code </span>
                                <span>quality </span>
                                <span>and </span>
                                <span>functionality. </span>
                                <span>⦿ </span>
                                <span>Looks </span>
                                <span>for </span>
                                <span>code </span>
                                <span>area </span>
                                <span>improvements. </span>
                                <span>⦿ </span>
                                <span>Follow </span>
                                <span>agile </span>
                                <span>methodology </span>
                                <span>to </span>
                                <span>bring </span>
                                <span>new </span>
                                <span>enhancements </span>
                                <span>live. </span>
                                <span>⦿ </span>
                                <span>Production </span>
                                <span>bug </span>
                                <span>Fixes. </span>
                                <span>⦿ </span>
                                <span>Scale </span>
                                <span>Servers </span>
                                <span>and </span>
                                <span>improve </span>
                                <span>performance.</span>
                              </div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                        <div className="experience-item">
                          <div className="experience-info">
                            <div className="date  with-space ">
                              <span className="date-numbers">
                                05/2020 – 01/2022
                              </span>
                            </div>
                            <div className="position">Software Engineer</div>
                            <div className="company-name">ABIS Cloud</div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0">
                                <span>⦿ </span>
                                <span>Working </span>
                                <span>as </span>
                                <span>a </span>
                                <span>Full-stack </span>
                                <span>developer. </span>
                                <span>⦿ </span>
                                <span>Brings </span>
                                <span>real-time </span>
                                <span>use </span>
                                <span>cases </span>
                                <span>live. </span>
                                <span>⦿ </span>
                                <span>Manage </span>
                                <span>Database </span>
                                <span>and </span>
                                <span>database </span>
                                <span>queries. </span>
                                <span>⦿ </span>
                                <span>Responsible </span>
                                <span>for </span>
                                <span>code </span>
                                <span>reusability </span>
                                <span>and </span>
                                <span>scalability. </span>
                                <span>⦿ </span>
                                <span>Code </span>
                                <span>deployment </span>
                                <span>and </span>
                                <span>continuous </span>
                                <span>integration.</span>
                              </div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                        <div className="experience-item">
                          <div className="experience-info">
                            <div className="date  with-space ">
                              <span className="date-numbers">
                                01/2020 – 09/2020
                              </span>
                              <span className="date-address">
                                <span>Pune, Maharashtra</span>
                              </span>
                            </div>
                            <div className="position">
                              Software Development Engineer
                            </div>
                            <div className="company-name">
                              Aviabird Technologies
                            </div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0">
                                <span>Aviabird </span>
                                <span>Technologies </span>
                                <span>with </span>
                                <span>the </span>
                                <span>motive </span>
                                <span>of </span>
                                <span>serving </span>
                                <span>best </span>
                                <span>and </span>
                                <span>reliably </span>
                                <span>responsible </span>
                                <span>for </span>
                                <span>the </span>
                                <span>best </span>
                                <span>customer </span>
                                <span>approach, </span>
                                <span>has </span>
                                <span>delivered </span>
                                <span>more </span>
                                <span>than </span>
                                <span>20+ </span>
                                <span>client </span>
                                <span>successfully. </span>
                                <span>Along </span>
                                <span>with </span>
                                <span>customers </span>
                                <span>satisfaction, </span>
                                <span>is </span>
                                <span>active </span>
                                <span>organizer </span>
                                <span>of </span>
                                <span>flutter </span>
                                <span>India.</span>
                              </div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                        <div className="experience-item">
                          <div className="experience-info">
                            <div className="date  with-space ">
                              <span className="date-numbers">
                                01/2020 – 09/2020
                              </span>
                              <span className="date-address">
                                <span>Pune, Maharashtra, India</span>
                              </span>
                            </div>
                            <div className="position">
                              Software Development Engineer
                            </div>
                            <div className="company-name">Aviahire</div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0">
                                <span>Aviahire, </span>
                                <span>makes </span>
                                <span>employee </span>
                                <span>hiring, </span>
                                <span>employee </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column right-column-render">
                      <div className="kickresume-section-wrapper experience-section  column-wrapper ">
                        <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                          &nbsp;
                        </div>
                        <h6 className="headline type-three mobile-hide">
                          <div className="background-wrapper">
                            <span className="icon fixed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M2.25 23.25A2.252 2.252 0 010 21V7.5a2.252 2.252 0 012.25-2.25H3V4.5a.75.75 0 011.5 0v.75h2.459l.987-2.962A2.247 2.247 0 0110.08.75h3.838c.97 0 1.827.618 2.135 1.538l.987 2.962h2.46V4.5a.75.75 0 011.5 0v.75h.75A2.252 2.252 0 0124 7.5V21a2.252 2.252 0 01-2.25 2.25H2.25zm0-16.5a.75.75 0 00-.75.75V21c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75V7.5a.75.75 0 00-.75-.75H2.25zm13.209-1.5l-.829-2.488a.749.749 0 00-.711-.512h-3.838a.748.748 0 00-.711.513L8.541 5.25h6.918z"></path>
                                <path d="M5.25 12a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25zm0 6a.75.75 0 010-1.5h13.5a.75.75 0 010 1.5H5.25z"></path>
                              </svg>
                            </span>
                            <span className="text">Work experience</span>
                          </div>
                        </h6>
                        <div className="experience-item">
                          <div className="experience-description">
                            <div part="1">
                              <div className="description " part="1">
                                <span>management, </span>
                                <span>employee </span>
                                <span>onboarding </span>
                                <span>similar. </span>
                                <span>All </span>
                                <span>solution </span>
                                <span>for </span>
                                <span>your </span>
                                <span>team </span>
                                <span>management </span>
                                <span>starting </span>
                                <span>from </span>
                                <span>Job </span>
                                <span>posting </span>
                                <span>to </span>
                                <span>employee </span>
                                <span>onboarding.</span>
                              </div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                        <div className="experience-item">
                          <div className="experience-info">
                            <div className="date  with-space ">
                              <span className="date-numbers">
                                09/2018 – 01/2020
                              </span>
                              <span className="date-address">
                                <span>Chandigarh Area, India</span>
                              </span>
                            </div>
                            <div className="position">Software Engineer</div>
                            <div className="company-name">Finxera</div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0">
                                <span>Finxera, </span>
                                <span>we </span>
                                <span>build </span>
                                <span>system </span>
                                <span>that </span>
                                <span>moves </span>
                                <span>real </span>
                                <span>money </span>
                                <span>for </span>
                                <span>you.</span>
                              </div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                      <div className="keywords-section column-wrapper kickresume-section-wrapper type-one center">
                        <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                          &nbsp;
                        </div>
                        <h6 className="headline type-three">
                          <div className="background-wrapper">
                            <span className="icon fixed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M6.09 23.3a2.207 2.207 0 01-2.12-2.8l1.53-5.43-4.43-3.49C.34 11 .05 10.06.34 9.17c.29-.89 1.07-1.48 2-1.52l5.63-.22 1.95-5.29C10.26 1.26 11.07.7 12 .7s1.74.56 2.06 1.44l1.96 5.29 5.63.22c.93.04 1.72.63 2 1.52.29.89 0 1.83-.73 2.41l-4.43 3.49 1.53 5.43c.25.9-.07 1.83-.83 2.38-.75.55-1.74.57-2.51.05L12 19.79l-4.69 3.13c-.37.25-.8.38-1.22.38zM12 2.3c-.1 0-.43.03-.56.39L9.45 8.08c-.21.55-.72.92-1.3.94l-5.74.23c-.39.02-.52.32-.55.41s-.11.42.2.66l4.51 3.56c.46.36.65.96.5 1.53l-1.56 5.53a.6.6 0 00.23.65c.08.06.36.23.69.01l4.78-3.19c.49-.33 1.12-.33 1.6 0l4.78 3.19c.32.21.61.04.69-.01.08-.06.33-.27.22-.65l-1.56-5.53c-.16-.56.04-1.16.49-1.53l4.51-3.56c.3-.24.23-.56.2-.66s-.16-.4-.55-.41l-5.74-.23c-.59-.02-1.1-.39-1.3-.95l-1.99-5.38A.58.58 0 0012 2.3zm4.05 5.22z"></path>
                              </svg>
                            </span>
                            <span className="text">Skills</span>
                          </div>
                        </h6>
                        <div className="keywords">
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Resource Management</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Business Analysis</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">
                                Software as a Service (SaaS)
                              </span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Financial Analysis</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">PostgreSQL</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">NestJS</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">
                                Application Programming
                              </span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Angular</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Agile Methodologies</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Git</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Redux.js</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">React.js</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Jira</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">JavaScript</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">AngularJS</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Web Development</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">HTML5</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">MongoDB</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">MySQL</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">javaScript</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">English</span>
                            </div>
                          </div>
                          <div className="keyword">
                            <div className="keyword-text-wrapper">
                              <span className="text">Hindi</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="kickresume-section-wrapper experience-section  column-wrapper ">
                        <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                          &nbsp;
                        </div>
                        <h6 className="headline type-three">
                          <div className="background-wrapper">
                            <span className="icon fixed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M23.2 8.3L12.3 3.6c-.2-.1-.4-.1-.6 0L.8 8.3c-.1 0-.2.1-.2.2l-.1.1c0 .1-.1.1-.1.2v7.1c0 .4.4.8.8.8s.8-.4.8-.8v-5.6l2.8 1.1V17c0 .3.2.6.5.7l6.5 2.8c.1 0 .2.1.3.1s.2 0 .3-.1l6.5-2.8c.3-.1.5-.4.5-.7v-5.7l3.9-1.5c.3-.1.5-.4.5-.7s-.3-.7-.6-.8zm-5.5 8.1L12 18.9l-5.7-2.5v-4.5l5.4 2.1c.1 0 .2.1.3.1s.2 0 .3-.1l5.4-2.1v4.5zm-5.7-4L3.2 9 12 5.2 20.8 9 12 12.4z"></path>
                              </svg>
                            </span>
                            <span className="text">Education</span>
                          </div>
                        </h6>
                        <div className="experience-item">
                          <div className="experience-info">
                            <div className="date  with-space ">
                              <span className="date-numbers">2013 – 2017</span>
                            </div>
                            <div className="position">
                              Bachelor of Technology (BTech)
                            </div>
                            <div className="company-name">
                              Kurukshetra University
                            </div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0"></div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                      <div className="kickresume-section-wrapper experience-section  column-wrapper ">
                        <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                          &nbsp;
                        </div>
                        <h6 className="headline type-three">
                          <div className="background-wrapper">
                            <span className="icon fixed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19.498 23.998a1.494 1.494 0 01-.879-.286l-6.177-4.493a.748.748 0 00-.882 0l-6.177 4.492a1.49 1.49 0 01-.881.286c-.479 0-.934-.231-1.215-.617A1.49 1.49 0 013 22.502V2.25A2.252 2.252 0 015.25 0h13.5A2.252 2.252 0 0121 2.25V22.5a1.504 1.504 0 01-1.5 1.499h-.002zM5.25 1.5a.75.75 0 00-.75.75V22.5l6.177-4.493A2.238 2.238 0 0112 17.576c.478 0 .935.149 1.323.431l6.177 4.492V2.25a.75.75 0 00-.75-.75H5.25z"></path>
                                <path d="M10.5 14.25c-.4 0-.776-.156-1.059-.438l-1.721-1.72a.752.752 0 01.531-1.281c.2 0 .388.078.53.219l1.72 1.719L15.97 7.28a.744.744 0 011.06 0 .744.744 0 010 1.06l-5.47 5.469a1.486 1.486 0 01-1.059.439l-.001.002z"></path>
                              </svg>
                            </span>
                            <span className="text">Publications</span>
                          </div>
                        </h6>
                        <div className="experience-item">
                          <div>
                            <div className="experience-info">
                              <div className="publication-name">
                                Review Paper On Image Processing
                              </div>
                              <div className="publisher">
                                Haryana Engineering College
                              </div>
                              <div className="url">
                                <a
                                  href="#"
                                  target="_blank"
                                  rel="noopener noreferrer nofollow ugc"
                                ></a>
                              </div>
                            </div>
                          </div>
                          <div className="experience-description">
                            <div part="0">
                              <div className="description " part="0"></div>
                            </div>
                          </div>
                          <div className="NoteDetails__note-detail___yB3Xx5OauH note-detail-item">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-ornament"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
